<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    :placeholder="placeholder"
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @blur="() => (findText = null)"
    @select="() => (findText = null)"
    v-bind="$attrs"
    :loading="loading"
    :show-arrow="true"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'

export default {
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    mode: {
      default: 'multiple',
      type: String,
    },
    placeholder: {
      default: 'Pilih',
      type: String,
    },
    group: {
      default: 'typeNews',
      type: String,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const data = ref([])
    const findText = ref(null)
    const group = toRefs(props).group
    const loading = ref(false)

    const fetchData = () => {
      loading.value = true
      apiClient
        .get('/api/pos-promo/lookup')
        .then(response => {
          let result = []
          if (group.value === 'type_news') {
            result = response?.data?.typeNews?.map(function (item) {
              return { id: item, name: item }
            }) ?? []
          }
          if (group.value === 'type_promo') {
            result = response?.data?.typePromo?.map(function (item) {
              return { id: item, name: item }
            }) ?? []
          }
          if (group.value === 'type_min_pembelian') {
            result = response?.data?.typeMinimalPembelian?.map(function (item) {
              return { id: item, name: item }
            }) ?? []
          }
          if (group.value === 'payment_methods') {
            result = response?.data?.paymentMethod?.map(function (item) {
              return { id: item.paymentValue, name: item.paymentName }
            }) ?? []
          }
          if (group.value === 'device') {
            result = response?.data?.device?.map(function (item) {
              return { id: item, name: item }
            }) ?? []
          }

          data.value = result
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    watch(group, (after, before) => {
      emit('update:value', [])
      fetchData()
    })

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    return {
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      loading,
    }
  },
}
</script>

<style></style>
