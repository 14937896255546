export default [
  {
    title: 'No',
    slots: {
      customRender: 'no',
    },
  },
  {
    title: 'Nama',
    dataIndex: 'name',
  },
  {
    title: 'Jenis Berita',
    dataIndex: 'type_news',
  },
  {
    title: 'Kode Promo',
    dataIndex: 'code_promo',
  },
  {
    title: 'Mulai tanggal',
    dataIndex: 'start_date',
    slots: { customRender: 'date' },
  },
  {
    title: 'Tanggal Berakhir',
    dataIndex: 'end_date',
    slots: { customRender: 'date' },
  },
  {
    title: 'Tanggal Publish',
    dataIndex: 'published_at',
    slots: { customRender: 'date' },
  },
  {
    title: 'Kuota',
    dataIndex: 'quota',
  },
  {
    title: 'Jumlah Pengguna',
    dataIndex: 'max_toko',
    width: 90,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    slots: { customRender: 'status' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]
